$(function() {
        // searchlist();
        //notification();
        mservicelink();
        filteraccordian();
      //  customselect();
        daterangePicker();
    })
    //searchlist function start here 
function searchlist() {
    $(".searchlist a").click(function(e) {
        $(".slistdrwn").slideToggle('fast');
    });
    $(".slistdrwn li").click(function(e) {
        var sval = "Enter" + " " + $(this).text();
        //alert(sval);
        $(".searchinput").attr('placeholder', sval);
        $(".searchinput1").val(sval);

        $(".slistdrwn").slideUp('fast');

    });

}

function notification() {
    $(".notif_ico").click(function() {
        $('.notifsec').slideToggle("fast");
        $('.logoutSec').slideUp("fast");
        $(".customfilter .cfillBox").slideUp('fast');
    });
    $('body,html').click(function() {

        $(".notifsec,.logoutSec,.mservicelink ul,.slistdrwn,.slistdrwn").slideUp("fast");

    });
    $('.notif_ico,.notifsec,.logoutSec,.logout,.mservicelink,.searchlist').click(function(event) {
        event.stopPropagation();
    });
}

function mservicelink() {
    $("div.mservicelink>a").click(function() {
        $(".mservicelink ul").slideUp('fast');
        $(this).next('ul').slideToggle('fast');

    });
    $('div.mservicelink ul li a').click(function() {
        $(this).parent('ul').slideUp('fast');
        var id = $(this).data('target');
        $(id).modal('show');

    })

}

function filteraccordian() {
    /* $(".customfilter small").click(function() {
         $(this).next('.cfillBox').slideToggle('fast');
         $(this).toggleClass('active');
     })*/
    $(document).on('click', '.customfilter small', function() {
        $(this).next('.cfillBox').slideToggle('fast');
        $(this).toggleClass('active');
        $(".select2").select2({
            minimumResultsForSearch: -1
        });
        daterangePicker();
    })

    $(document).on('click', '.cfillBox .greybtn', function() {

        $(".cfillBox").slideUp("fast");
    })

}

function customselect() {
    $(".select2").select2({
        minimumResultsForSearch: -1
    });
}

function daterangePicker() {
    var dateFormat = "yy-mm-dd",
        from = $("#fromdate,#fromdate1")
        .datepicker({
            defaultDate: "+1w",
            changeMonth: true,
            changeYear: true,
            numberOfMonths: 1,
            showOn: "button",
            buttonImage: "assets/img/cal_icon.png",
            buttonImageOnly: true,
            dateFormat: "yy-mm-dd",
            yearRange: "2014:+0",
            maxDate: 0
        })
        .on("change", function() {
            to.datepicker("option", "minDate", getDate(this));
        }),
        to = $("#todate,#todate1").datepicker({
            defaultDate: "+1w",
            changeMonth: true,
            changeYear: true,
            numberOfMonths: 1,
            showOn: "button",
            buttonImage: "assets/img/cal_icon.png",
            buttonImageOnly: true,
            dateFormat: "yy-mm-dd",
            yearRange: "2014:+0",
            maxDate: 0
        })
        .on("change", function() {
            from.datepicker("option", "maxDate", getDate(this));
        });

    function getDate(element) {
        var date;
        try {
            date = $.datepicker.parseDate(dateFormat, element.value);
        } catch (error) {
            date = null;
        }

        return date;
    }
}

function isNumber(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
}